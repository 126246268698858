.group-box {
    position: relative;
    margin-bottom: 10px;
    padding-top: 0px;
    /* Added padding for title */
    border: 1px solid #ccc;
    border-radius: 4px;
}

.group-box-header {
    position: absolute;
    top: -15px;
    /* Adjust the distance from the top */
    left: 10px;
    /* Adjust the distance from the right */
    background-color: #f9f9f9;
    padding: 5px;
    border-radius: 4px;
    font-weight: normal;
    font-style: normal;
    font-size: 13px;
    color: #8f8f8f;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.group-box-body {
    padding: 10px;
    /*background-color: #f9f9f9;*/
}