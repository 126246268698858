.leftPart {
    padding-top: 2px;
    padding-left: 10px;
    padding-right: 2px;
    padding-bottom: 2px;
}

.rightPart {
    padding-top: 2px;
    padding-left: 2px;
    padding-right: 10px;
    padding-bottom: 2px;
}